import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DurationDisplay } from './DurationDisplay';
import { gettext } from '../../../../../core/utils/text';

interface TimeTracker {
    id: number;
    start_time: string;
    end_time: string | null;
    work_order: number;
}

type TimeTrackersDurationDisplayProps = {
    timeTrackers: TimeTracker[];
    plannedDuration: number;
};

export const TimeTrackersTimeRemaining: React.FC<TimeTrackersDurationDisplayProps> = ({
    timeTrackers,
    plannedDuration,
}) => {
    const [totalDuration, setTotalDuration] = useState<moment.Duration>(
        moment.duration(0)
    );

    useEffect(() => {
        const interval = setInterval(() => {
            const calculatedDuration = timeTrackers.reduce(
                (acc, timeTracker) => {
                    const startTime = moment(timeTracker.start_time);
                    const endTime = timeTracker.end_time
                        ? moment(timeTracker.end_time)
                        : moment();
                    const duration = moment.duration(endTime.diff(startTime));
                    return acc.add(duration);
                },
                moment.duration(0)
            );
            setTotalDuration(calculatedDuration);
        }, 1000);
        return () => clearInterval(interval);
    }, [timeTrackers]);

    if (timeTrackers.length === 0) {
        return null;
    }
    const remaining = plannedDuration - totalDuration.asMinutes();
    return (
        <table>
            <tbody>
                <tr>
                    <td>
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip">
                                    {remaining > 0
                                        ? gettext('Time remaining')
                                        : gettext('Time exceeding')}
                                </Tooltip>
                            }
                        >
                            <div>
                                <DurationDisplay
                                    minutes={
                                        plannedDuration -
                                        totalDuration.asMinutes()
                                    }
                                />
                            </div>
                        </OverlayTrigger>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default TimeTrackersTimeRemaining;
