import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderPropConfirmModal from 'core/components/RenderPropConfirmModal';
import { gettext } from 'core/utils/text';
import { stopTimeTrackerRoutine } from 'parts/operations/ducks/timeTrackers';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const StopButton = ({
    latestTrackerId,
    stopTimeTracker,
    disabled,
    children,
}) => (
    <RenderPropConfirmModal
        title={gettext(
            'Are you sure you want to stop working on this work order?',
        )}
        onConfirm={() => stopTimeTracker({ id: latestTrackerId })}
        modalContent={
            // eslint-disable-next-line max-len
            <p>
                {gettext(
                    'All work related to this work order will be marked as "Done" and the work order will disappear from your work planner.',
                )}
            </p>
        }
    >
        {({ requestConfirm }) => (
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    requestConfirm();
                }}
                disabled={disabled}
            >
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip">
                            {gettext('Stop time tracker')}
                        </Tooltip>
                    }
                >
                    {children}
                </OverlayTrigger>
            </button>
        )}
    </RenderPropConfirmModal>
);

StopButton.propTypes = {
    latestTrackerId: PropTypes.number,
    stopTimeTracker: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    children: PropTypes.node,
};

StopButton.defaultProps = {
    latestTrackerId: null,
};

export default connect(null, { stopTimeTracker: stopTimeTrackerRoutine })(
    StopButton,
);
