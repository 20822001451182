import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DurationDisplay } from './DurationDisplay';
import { gettext } from '../../../../../core/utils/text';
import styles from './WorkOrderHeader.scss';

interface DownTime {
    id: number;
    start_time: string;
    end_time: string | null;
    work_order: number;
    problems: {
        id: number;
        name: string;
    }[];
}

type DownTimeDisplayProps = {
    downTimes: DownTime[];
    showHeader: boolean;
    warning?: boolean;
};

export const DownTimeDisplay: React.FC<DownTimeDisplayProps> = ({
    downTimes,
    showHeader,
    warning = true,
}) => {
    const [totalDuration, setTotalDuration] = useState<moment.Duration>(
        moment.duration(0)
    );

    useEffect(() => {
        const interval = setInterval(() => {
            const calculatedDuration = downTimes.reduce((acc, timeTracker) => {
                const startTime = moment(timeTracker.start_time);
                const endTime = timeTracker.end_time
                    ? moment(timeTracker.end_time)
                    : moment();
                const duration = moment.duration(endTime.diff(startTime));
                return acc.add(duration);
            }, moment.duration(0));
            setTotalDuration(calculatedDuration);
        }, 1000);
        return () => clearInterval(interval);
    }, [downTimes]);

    if (downTimes.length === 0) {
        return null;
    }

    return (
        <>
            {showHeader && (
                <tr>
                    <td className={styles.downTimeRow} colSpan={2}>
                        {gettext('Down Times')}
                    </td>
                </tr>
            )}

            {downTimes.map((downTime) => (
                <tr key={downTime.id}>
                    <td>
                        {downTime.problems.map((problem) => (
                            <div key={problem.id}>{problem.name}</div>
                        ))}
                    </td>
                    <td>
                        <DurationDisplay
                            warning={warning}
                            minutes={moment
                                .duration(
                                    moment(downTime.end_time || undefined).diff(
                                        moment(downTime.start_time)
                                    )
                                )
                                .asMinutes()}
                        />
                    </td>
                </tr>
            ))}

            {downTimes.length > 1 && (
                <tr>
                    <td>{gettext('Total down time')}:</td>
                    <td className={styles.downTimeRow}>
                        <DurationDisplay minutes={totalDuration.asMinutes()} />
                    </td>
                </tr>
            )}
        </>
    );
};

export default DownTimeDisplay;
