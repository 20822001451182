import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import RenderPropConfirmModal from 'core/components/RenderPropConfirmModal';
import {
    downtimesSelectors,
    solveDowntimeRoutine,
} from 'parts/operations/ducks/downtimes';
import { downtimePropType } from 'parts/operations/propTypes';
import { gettext } from 'core/utils/text';

const SolveDowntimeButton = ({
    latestDowntime,
    solveDowntime,
    disabled,
    children,
}) => (
    <RenderPropConfirmModal
        title={gettext("Are you sure you'd like to solve the downtime?")}
        onConfirm={() => solveDowntime(latestDowntime)}
        modalContent={
            <p>
                {gettext('Work will be marked as "In Progress" status again.')}
            </p>
        }
    >
        {({ requestConfirm }) => (
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    requestConfirm();
                }}
                disabled={disabled}
            >
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id="tooltip">
                            {gettext('Mark as solved')}
                        </Tooltip>
                    }
                >
                    {children}
                </OverlayTrigger>
            </button>
        )}
    </RenderPropConfirmModal>
);

SolveDowntimeButton.propTypes = {
    latestDowntime: downtimePropType,
    solveDowntime: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    children: PropTypes.node,
};

const mapStateToProps = (state, ownProps) => ({
    latestDowntime: downtimesSelectors.getByWorkOrderId(
        state,
        ownProps.workOrderId,
    ),
});

export default connect(mapStateToProps, {
    solveDowntime: solveDowntimeRoutine,
})(SolveDowntimeButton);
