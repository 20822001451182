import { SET_IS_ONLINE } from '../actions/types';
import { queriesApi } from '../../queries';

// eslint-disable-next-line
function isOnline(state = true, action) {
    switch (action.type) {
        case SET_IS_ONLINE:
            return action.isOnline;
        default:
            return state;
    }
}

export const globalReducers = {
    isOnline,
    api: queriesApi.reducer,
};
