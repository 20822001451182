import React from 'react';
import moment from 'moment';
import { clsx } from 'clsx';
import styles from './DurationDisplay.scss';

type DurationDisplayProps = {
    minutes: number;
    warning?: boolean;
};

export const DurationDisplay: React.FC<DurationDisplayProps> = ({
    minutes,
    warning,
}) => {
    const isNeg = minutes < 0;
    const duration = moment.duration(isNeg ? minutes * -1 : minutes, 'minutes');
    const hours = Math.floor(duration.asHours());
    const remainingMinutes = duration.minutes();
    const remainingSeconds = duration.seconds();
    return (
        <div
            className={clsx({
                [styles.negitive]: isNeg || warning,
            })}
        >
            {isNeg && <span>-</span>}
            {hours > 0 && <span>{hours}h </span>}
            {(hours > 0 || remainingMinutes > 0) && (
                <span>{remainingMinutes}min </span>
            )}
            {remainingSeconds > 0 ? <span>{remainingSeconds}s</span> : null}
        </div>
    );
};
