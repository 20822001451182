import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { pauseTimeTrackerRoutine } from 'parts/operations/ducks/timeTrackers';
import { gettext } from 'core/utils/text';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const PauseButton = ({
    latestTrackerId,
    pauseTimeTracker,
    disabled,
    children,
}) => (
    <button
        onClick={(e) => {
            e.stopPropagation();
            pauseTimeTracker({ id: latestTrackerId });
        }}
        disabled={disabled}
    >
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id="tooltip">{gettext('Pause time tracker')}</Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    </button>
);

PauseButton.propTypes = {
    latestTrackerId: PropTypes.number,
    disabled: PropTypes.bool,
    pauseTimeTracker: PropTypes.func.isRequired,
    children: PropTypes.node,
};

PauseButton.defaultProps = {
    latestTrackerId: null,
};

export default connect(null, { pauseTimeTracker: pauseTimeTrackerRoutine })(
    PauseButton,
);
