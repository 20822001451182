import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SuperAgentResource as Resource } from '@tg-resources/superagent';

import { setIsOnline } from '../actions/globalActions';
import ErrorFloat from './ErrorFloat';
import { gettext } from '../utils/text';

class ConnectionStatus extends Component {
    static propTypes = {
        isOnline: PropTypes.bool,
        setIsOnline: PropTypes.func,
    };

    componentDidMount() {
        window.addEventListener('online', this.onOnline);
        window.addEventListener('offline', this.onOffline);
        this.connectionTimeout = setTimeout(this.checkConnection, 5000);
    }

    componentWillUnmount() {
        window.removeEventListener('online', this.onOnline);
        window.removeEventListener('offline', this.onOffline);
        clearTimeout(this.connectionTimeout);
    }

    onOffline = () => {
        this.props.setIsOnline(false);
    };

    onOnline = () => {
        this.props.setIsOnline(true);
    };

    checkConnection = () => {
        new Resource(window.location)
            .head()
            .then(() => {
                if (!this.props.isOnline) {
                    this.props.setIsOnline(true);
                }
                setTimeout(this.checkConnection, 5000);
            })
            .catch(() => {
                if (this.props.isOnline) {
                    this.props.setIsOnline(false);
                }
                setTimeout(this.checkConnection, 5000);
            });
    };

    render() {
        const errorMsg = gettext(
            'Lost internet connectivity. ' +
                'Do not refresh the page before the connection has been re-established, ' +
                'otherwise any unsaved changes will be lost.',
        );

        return (
            !this.props.isOnline && (
                <ErrorFloat closable={false}>{errorMsg}</ErrorFloat>
            )
        );
    }
}

const mapStateToProps = (state) => ({
    isOnline: state.isOnline,
});

const mapDispatchToProps = (dispatch) => ({
    setIsOnline: (isOnline) => dispatch(setIsOnline(isOnline)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionStatus);
