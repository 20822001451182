import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import moment from 'moment';
import { gettext } from 'core/utils/text';

import PartProductComment from 'parts/components/PartProductComment';
import {
    workOrderDenormalizedPropType,
    workPlanPropType,
} from 'parts/workplan/propTypes';
import { WORK_ORDER_ACTION_STATUSES } from 'parts/workplan/constants';
import {
    actionWorkPlanPath,
    flattenTree,
    forEachAction,
} from 'parts/utils/parts';
import {
    mapWorkPlanToTreeItems,
    toggleOpenTreeItem,
} from 'parts/workplan/utils/treeItems';

import {
    FaSquare,
    FaMinusSquare,
    FaWrench,
    FaChevronUp,
    FaChevronDown,
} from 'react-icons/fa';
import { FaSquareCheck } from 'react-icons/fa6';
import styles from './OperationsListWorkPlanTree.scss';
import { PartProductPropType } from '../../../../propTypes';
import { WorkOrderHeaderTablesConnected } from './WorkOrderHeader';

/**
 * Since we can't directly relate a work order action to an action in the work
 * plan, we need to do some calculations to make sure that we correctly match
 * all work order actions.
 *
 * @param workPlan {import('parts/timeplan/reducers/timeplanReducers').WorkPlan}
 *
 * @param workOrder {import('parts/workplan/ducks/workOrders').WorkOrderDeserialized}
 *
 * @returns {Object.<string, number>} Action work plan path mapped to work
 *  order action ID
 */
export const getWorkOrderMatchingWorkPlanActions = (workPlan, workOrder) => {
    const workOrderActionsLeftToMatch = [...workOrder.work_order_actions];
    const workPlanActionsMappedToWorkOrders = {};

    forEachAction(
        { workPlan, unassignedActionDurations: {} },
        (action, componentPart, component) => {
            workOrderActionsLeftToMatch.find((workOrderAction, index) => {
                const workPlanPath = actionWorkPlanPath(
                    action,
                    componentPart,
                    component,
                    { work_plan_json: workPlan },
                );

                const { action: woAction } = workOrderAction;
                const { worker } = workOrder;

                if (woAction.id === action.id && worker === action.worker_id) {
                    workPlanActionsMappedToWorkOrders[workPlanPath] =
                        workOrderAction.id;
                    workOrderActionsLeftToMatch.splice(index, 1);
                    return true;
                }

                return false;
            });
        },
    );

    if (workOrderActionsLeftToMatch.length) {
        console.warn(
            "Did not manage to match all work order actions! This probably shouldn't happen.",
            { workOrderActionsLeftToMatch },
        );
    }

    return workPlanActionsMappedToWorkOrders;
};
/**
 * Helper function to not have to deal with the complexity of workorderJSON and TreeItems
 *
 * @param workOrderActionsByWorkPlanActionPath {Object.<string, number>} Action work plan path mapped to work
 *
 * @returns {function(treeItem:TreeItem): number[]}
 *
 */
export const getWorkOrderActionIdAndChildrenIdsFromTreeItem = (
    workOrderActionsByWorkPlanActionPath,
) => (mainItem) => {
    const items = [
        mainItem,
        ...flattenTree(mainItem.children, (i) => i.children),
    ];
    return items.reduce((ids, item) => {
        const relatedWorkOrderActionId =
            workOrderActionsByWorkPlanActionPath[item.workPlanPath];
        if (relatedWorkOrderActionId) {
            ids.push(relatedWorkOrderActionId);
        }
        return ids;
    }, []);
};

const OperationsListWorkPlanTree = ({
    workPlan,
    editedStatuses,
    originalStatuses,
    changeStatus,
    workOrder,
    readOnly,
    partProduct,
}) => {
    const [openedItems, setOpenedItems] = useState({
        component: [],
        part: [],
    });

    const workOrderActionsByWorkPlanActionPath = getWorkOrderMatchingWorkPlanActions(
        workPlan,
        workOrder,
    );

    const getWorkOrderActionIdAndChildrenIds = getWorkOrderActionIdAndChildrenIdsFromTreeItem(
        workOrderActionsByWorkPlanActionPath,
    );

    const getStatusIcon = (status) =>
        ({
            [WORK_ORDER_ACTION_STATUSES.NOT_STARTED]: FaSquare,
            [WORK_ORDER_ACTION_STATUSES.IN_PROGRESS]: FaMinusSquare,
            [WORK_ORDER_ACTION_STATUSES.DONE]: FaSquareCheck,
        }[status]);

    const getStatusTile = (status) =>
        ({
            [WORK_ORDER_ACTION_STATUSES.NOT_STARTED]: gettext('Not started'),
            [WORK_ORDER_ACTION_STATUSES.IN_PROGRESS]: gettext('In progress'),
            [WORK_ORDER_ACTION_STATUSES.DONE]: gettext('Done'),
        }[status]);

    const getItemIcon = (isAction, expanded) => {
        if (isAction) {
            return FaWrench;
        } else if (expanded) {
            return FaChevronUp;
        } else {
            return FaChevronDown;
        }
    };

    /**
     * @param item {TreeItem}
     * @param parentIsComponent {boolean}
     * @returns {JSX.Element}
     */
    const renderRow = (item, parentIsComponent) => {
        const isComponent = item.type === 'component';
        const isPart = item.type === 'part';
        const isAction = item.type === 'action';

        const { workPlanPath } = item;
        const relatedWorkOrderActionId =
            workOrderActionsByWorkPlanActionPath[workPlanPath];
        const relatedWorkOrderAction = workOrder.work_order_actions.find(
            (woAction) => woAction.id === relatedWorkOrderActionId,
        );

        // workOrderActionChildrenIds include current action ID if it's an action and all children
        const workOrderActionChildrenIds = getWorkOrderActionIdAndChildrenIds(
            item,
        );
        const workOrderActionChildrenCurrentStatuses = workOrderActionChildrenIds.map(
            (id) =>
                editedStatuses[id] !== undefined
                    ? editedStatuses[id]
                    : originalStatuses[id],
        );

        const workOrderActionCurrentStatusesSet = new Set(
            workOrderActionChildrenCurrentStatuses,
        );
        const currentStatus =
            workOrderActionCurrentStatusesSet.size === 1
                ? [...workOrderActionCurrentStatusesSet.values()][0]
                : WORK_ORDER_ACTION_STATUSES.IN_PROGRESS;

        const isComplete = workOrderActionChildrenIds
            .map((id) => originalStatuses[id])
            .every((s) => s === WORK_ORDER_ACTION_STATUSES.DONE);

        const StatusIcon = getStatusIcon(currentStatus);
        const ItemIcon = getItemIcon(isAction, item.expanded);

        return (
            <Fragment key={item.id}>
                <div
                    className={clsx(styles.row, {
                        [styles.part]: isPart,
                        [styles.action]: isAction,
                        [styles.bodyAction]: isAction && parentIsComponent,
                    })}
                    onClick={() =>
                        setOpenedItems(toggleOpenTreeItem(item, openedItems))
                    }
                >
                    <div className={styles.column}>
                        <StatusIcon
                            className={clsx(styles.icon, {
                                [styles.success]: isComplete,
                                [styles.readOnly]: readOnly && !isComplete,
                            })}
                            title={getStatusTile(currentStatus)}
                            onClick={(event) => {
                                event.stopPropagation();
                                if (readOnly) {
                                    return;
                                }
                                changeStatus(
                                    workOrderActionChildrenIds,
                                    [
                                        WORK_ORDER_ACTION_STATUSES.IN_PROGRESS,
                                        WORK_ORDER_ACTION_STATUSES.NOT_STARTED,
                                    ].includes(currentStatus)
                                        ? WORK_ORDER_ACTION_STATUSES.DONE
                                        : WORK_ORDER_ACTION_STATUSES.NOT_STARTED,
                                );
                            }}
                        />

                        <ItemIcon
                            className={clsx(styles.icon, {
                                [styles.chevronUp]: item.expanded,
                            })}
                        />
                        {item.label}
                        {item.numberOfChildren && (
                            <span className="children-count">
                                <b>
                                    {`${
                                        workOrderActionChildrenCurrentStatuses.filter(
                                            (s) =>
                                                s ===
                                                WORK_ORDER_ACTION_STATUSES.DONE,
                                        ).length
                                    } / ${item.numberOfChildren}`}
                                </b>
                            </span>
                        )}
                        {isPart && <span>&nbsp;OD/L: {item.odl}</span>}
                        {item.comment && (
                            <PartProductComment>
                                {item.comment}
                            </PartProductComment>
                        )}
                        {item.component_tags && (
                            <div className={styles.componentTags}>
                                {item.component_tags.map((tag) => (
                                    <span
                                        className={`label label-info ${styles.tagLabel}`}
                                    >
                                        {tag.name}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                    {!!relatedWorkOrderAction?.updated_at && (
                        <div className={styles.column}>
                            {moment(relatedWorkOrderAction.updated_at).format(
                                'DD.MM.YYYY HH:mm',
                            )}
                        </div>
                    )}
                </div>
                {item.expanded &&
                    item.children.map((child) => renderRow(child, isComponent))}
            </Fragment>
        );
    };

    const tree = mapWorkPlanToTreeItems(workPlan, openedItems);
    return (
        <div className={styles.container}>
            <WorkOrderHeaderTablesConnected
                inExpanded
                workOrder={workOrder}
                partProduct={partProduct}
            />
            <div className={styles.header}>
                <div className={styles.column}>{gettext('Order')}</div>
                <div className={styles.column}>{gettext('Last change')}</div>
            </div>
            {tree.map((item) => item.children.length > 0 && renderRow(item))}
        </div>
    );
};

OperationsListWorkPlanTree.propTypes = {
    workPlan: workPlanPropType.isRequired,
    workOrder: workOrderDenormalizedPropType.isRequired,
    editedStatuses: PropTypes.objectOf(
        PropTypes.oneOf(Object.values(WORK_ORDER_ACTION_STATUSES)),
    ).isRequired,
    originalStatuses: PropTypes.objectOf(
        PropTypes.oneOf(Object.values(WORK_ORDER_ACTION_STATUSES)),
    ).isRequired,
    changeStatus: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    partProduct: PartProductPropType.isRequired,
};

export default OperationsListWorkPlanTree;
