import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

export default class Expire extends Component {
    static propTypes = {
        delay: PropTypes.number.isRequired,
        children: PropTypes.node,
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    componentDidMount() {
        this.setTimer();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // reset the timer if children are changed
        if (nextProps.children !== this.props.children) {
            this.setTimer();
            this.setState({ visible: true });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    setTimer = () => {
        // clear any existing timer
        if (this.timer != null) {
            clearTimeout(this.timer);
        }

        // Make sure to show the message
        this.setState({ visible: true });

        // hide after `delay` milliseconds
        this.timer = setTimeout(() => {
            this.setState({ visible: false });
            this.timer = null;
        }, this.props.delay);
    };

    render() {
        return (
            <CSSTransition
                in={this.state.visible}
                unmountOnExit
                classNames="expire-fade"
                timeout={{ enter: 500, exit: 300 }}
            >
                <div key="expire-message">{this.props.children}</div>
            </CSSTransition>
        );
    }
}
