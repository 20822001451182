import { combineReducers } from 'redux';

import listFilters from 'core/ducks/listFilters';
import { errors, loading } from 'core/ducks/common';
import workOrdersReducer, {
    STATE_KEY as workOrdersKey,
} from 'parts/workplan/ducks/workOrders';
import workOrderActionsReducer, {
    STATE_KEY as workOrderActionsKey,
} from 'parts/workplan/ducks/workOrderActions';
import partProductsReducer, {
    STATE_KEY as partProductsKey,
} from 'parts/operations/ducks/partProducts';
import timeTrackersReducer, {
    STATE_KEY as timeTrackersKey,
} from 'parts/operations/ducks/timeTrackers';
import downtimesReducer, {
    STATE_KEY as DOWNTIMES_KEY,
} from 'parts/operations/ducks/downtimes';
import actionsReducer, {
    STATE_KEY as ACTIONS_KEY,
} from 'parts/operations/ducks/actions';
import { globalReducers } from 'core/reducers/globalReducers';

export default combineReducers({
    entities: combineReducers({
        [workOrdersKey]: workOrdersReducer,
        [workOrderActionsKey]: workOrderActionsReducer,
        [partProductsKey]: partProductsReducer,
        [timeTrackersKey]: timeTrackersReducer,
        [DOWNTIMES_KEY]: downtimesReducer,
        [ACTIONS_KEY]: actionsReducer,
    }),
    loading,
    errors,
    listFilters,
    ...globalReducers,
});
