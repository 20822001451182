import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { startTimeTrackerRoutine } from 'parts/operations/ducks/timeTrackers';
import { gettext } from 'core/utils/text';

import { clsx } from 'clsx';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from '../WorkOrderHeader.scss';

const StartButton = ({ workOrderId, startTimeTracker, disabled, children }) => (
    <button
        onClick={(e) => {
            if (disabled) {
                return;
            }
            e.stopPropagation();
            startTimeTracker({ work_order: workOrderId });
        }}
        className={clsx({
            [styles.disabled]: disabled,
        })}
        disabled={disabled}
    >
        <OverlayTrigger
            placement="top"
            overlay={
                <Tooltip id="tooltip">{gettext('Start time tracker')}</Tooltip>
            }
        >
            {children}
        </OverlayTrigger>
    </button>
);

StartButton.propTypes = {
    workOrderId: PropTypes.number.isRequired,
    startTimeTracker: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    children: PropTypes.node,
};

export default connect(null, { startTimeTracker: startTimeTrackerRoutine })(
    StartButton,
);
