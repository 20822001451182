import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DurationDisplay } from './DurationDisplay';
import { gettext } from '../../../../../core/utils/text';

interface TimeTracker {
    id: number;
    start_time: string;
    end_time: string | null;
    work_order: number;
}

type TimeTrackersDurationDisplayProps = {
    timeTrackers: TimeTracker[];
    plannedDuration: number;
    showTimeRemaining: boolean;
};

export const TimeTrackersDurationDisplay: React.FC<TimeTrackersDurationDisplayProps> = ({
    timeTrackers,
    plannedDuration,
    showTimeRemaining,
    children,
}) => {
    const [totalDuration, setTotalDuration] = useState<moment.Duration>(
        moment.duration(0)
    );

    useEffect(() => {
        const interval = setInterval(() => {
            const calculatedDuration = timeTrackers.reduce(
                (acc, timeTracker) => {
                    const startTime = moment(timeTracker.start_time);
                    const endTime = timeTracker.end_time
                        ? moment(timeTracker.end_time)
                        : moment();
                    const duration = moment.duration(endTime.diff(startTime));
                    return acc.add(duration);
                },
                moment.duration(0)
            );
            setTotalDuration(calculatedDuration);
        }, 1000);
        return () => clearInterval(interval);
    }, [timeTrackers]);

    if (timeTrackers.length === 0) {
        return null;
    }

    return (
        <>
            <tr>
                <td>{gettext('Time planned')}:</td>
                <td>
                    <DurationDisplay minutes={plannedDuration} />
                </td>
            </tr>
            <tr>
                <td>{gettext('Time progressed')}:</td>
                <td>
                    <DurationDisplay minutes={totalDuration.asMinutes()} />
                </td>
            </tr>
            {/* We show this elsewhere, but need to show this time when it's finished */}
            {showTimeRemaining && (
                <tr>
                    <td>{gettext('Time remaining')}:</td>
                    <td>
                        <DurationDisplay
                            minutes={
                                plannedDuration - totalDuration.asMinutes()
                            }
                        />
                    </td>
                </tr>
            )}
            {children}
        </>
    );
};

export default TimeTrackersDurationDisplay;
