// PipeForm Actions
export const SET_FORM_PIPE = 'qrs/SET_FORM_PIPE';
export const RECEIVE_PIPE_HISTORY = 'qrs/RECEIVE_PIPE_HISTORY';
export const RECEIVE_PIPE_MEASUREMENTS_DATA =
    'qrs/RECEIVE_PIPE_MEASUREMENTS_DATA';
export const CLEAR_PIPE_MEASUREMENTS_DATA = 'qrs/CLEAR_PIPE_MEASUREMENTS_DATA';
export const TRIGGER_PIPE_FORM_ERROR = 'qrs/TRIGGER_PIPE_FORM_ERROR';
export const TRIGGER_PIPE_FORM_SUCCESS = 'qrs/TRIGGER_PIPE_FORM_SUCCESS';
export const TOGGLE_IS_SAVING = 'qrs/TOGGLE_IS_SAVING';
export const SET_HAS_SAVED = 'qrs/SET_HAS_SAVED';

// PipeList Actions
export const UPDATE_CURSOR = 'qrs/UPDATE_CURSOR';
export const RECEIVE_PIPES = 'qrs/RECEIVE_PIPES';
export const RECEIVE_STATISTICS = 'qrs/RECEIVE_STATISTICS';
export const RECEIVE_PIPE = 'qrs/RECEIVE_PIPE';
export const TOGGLE_IS_LOADING = 'qrs/TOGGLE_IS_LOADING';
export const SET_IS_LOADING = 'qrs/SET_IS_LOADING';
export const TRIGGER_PIPE_LIST_ERROR = 'qrs/TRIGGER_PIPE_LIST_ERROR';
export const CLEAR_PIPES = 'qrs/CLEAR_PIPES';

// Misc Actions
export const RECEIVE_OPERATORS = 'qrs/RECEIVE_OPERATORS';
export const SET_MEASURED_PIPE = 'qrs/SET_MEASURED_PIPE';
